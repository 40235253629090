import React, { useState } from 'react';
import classNames from 'classnames';

import './styles/index.scss';

const PaymentsConsiderations = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => setCollapsed(!collapsed);

  return (
    <div className={classNames("card card-warning collapsible", {
      'open': collapsed
    })}>
      <button type="button" className={classNames("warning-container collapsible-btn", {
        'active': collapsed
      })} onClick={handleCollapsed}>
        <img
          src="https://public-kriptonmarket.s3.amazonaws.com/img/process_payment/warning.svg"
          alt="Warning"
        />

        <p>Ver consideraciones al momento de realizar el pago</p>
      </button>

      <div className="collapsible-content">
        <div className="warning-payment-container">
          <p className="warning-payment-text">
            Debe recibirse monto exacto para validar el pago.
          </p>
          <p className="warning-payment-text-bold">
            Para envios por exchange:
          </p>

          <div className="warning-payment-exchange-container">
            <div className="warning-payment-exchange-item-card">
              <img
                src="https://public-kriptonmarket.s3.amazonaws.com/img/maillers/fee_about_exchanges.svg"
                alt="Comisiones"
              />
              <span>
                Las comisiones por retiro quedan a cargo del Cliente
              </span>
            </div>

            <div className="warning-payment-exchange-item-card">
              <img
                src="https://public-kriptonmarket.s3.amazonaws.com/img/maillers/time_to_transfers.svg"
                alt="Tiempo"
              />
              <span>
                Los tiempos quedan sujetos a las políticas de su entidad de confianza
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentsConsiderations
