import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useFormik } from 'formik';

import Card from '../../components/Card';
import Input from '../../components/input';
import Button from '../../components/button';
import SelectCrypto from '../../components/selectCrypto';
import ModalError from '../../components/ModalErrorPaymentLink';

import { useQuery } from '../../hooks';
import { getPaymentLinkInfo, savePaymentLink } from '../../redux/actionsCreators';
import { FIELDS_NAME, initialValues, validationSchema } from './utils'
import Constants from '../../utils/constants';

import './styles/index.scss';

const {
  INPUTS_TYPES: { AMOUNT, TEXT },
  BUTTON: { NEXT },
} = Constants;

const PaymentLink = () => {
  const { payment_link_token: paymentLinkToken } = useParams();
  const query = useQuery();
  const integrationToken = query.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentLinkToken) {
      dispatch(getPaymentLinkInfo(paymentLinkToken, integrationToken));
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    config: {
      form: {
        operation_data: operationData = {},
      } = {}
    },
    currencies,
    paymentLink: {
      info,
      errorCreate,
    },
  } = useSelector(state => state.Payments);


  const handleSubmit = useCallback(
    (valuesForm) => {
      const dataToSend = {
        crypto_network_id: valuesForm[FIELDS_NAME.CRYPTO_RED],
      }
      
      dispatch(savePaymentLink(paymentLinkToken, integrationToken, dataToSend))
    },
    [dispatch, paymentLinkToken, integrationToken],
  );

  const {
    handleSubmit: submitForm,
    setFieldValue,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChange = useCallback(
    (fieldName, parseVal) => (value) => {
      const currentVal = parseVal ? parseVal(value) : value;

      setFieldValue(fieldName, currentVal);
    },
    [setFieldValue],
  );

  const parseCryptoRed = useCallback(
    (currentVal) => currentVal ? currentVal.split('-')[2] : '',
    [],
  );

  const currencyValue = useMemo(() => {
    const { currency_id: currencyId } = info;

    if (currencyId) {
      const selectedValue = currencies.find((currency) => currency?.value === currencyId);
      if (selectedValue) {
        return selectedValue.label;
      }
    }

    return '';
  }, [info, currencies]);

  const amountValue = useMemo(() => {
    const { amount } = info;

    if (amount) {
      return parseFloat(amount).toString();
    }

    return '';
  }, [info]);

  return (
    <Card className="payments">
      <div className="payments-container">
        <div className="payments-content">
          <h1 className="payments-ttile">{operationData.title}</h1>
          <div className="form-group">
            <h3 className="payments-labels">Criptomoneda y red de pago</h3>
            <SelectCrypto handleCrypto={handleChange(FIELDS_NAME.CRYPTO_RED, parseCryptoRed)} />
          </div>
        </div>
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Moneda de pago</h3>
            <Input type={TEXT} defaultValue={currencyValue} disabled />
          </div>
        </div>
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Monto </h3>
            <Input type={AMOUNT} defaultValue={amountValue} disabled  />
          </div>
        </div>
        <div className="payments-content">
          <div className="payments-right__button">
            <Button
              title={NEXT}
              type="primary"
              disabled={!dirty || !isValid || !currencyValue || !amountValue} 
              callbak={submitForm} 
            />
          </div>
        </div>
      </div>

      <ModalError show={errorCreate} />
    </Card>
  );
}

export default PaymentLink;
