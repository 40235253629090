import WalletConnect from "@walletconnect/client";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

// Create a connector
const connector = new WalletConnect({
  bridge: "https://bridge.walletconnect.org", // Required
});

connector.on("session_update", (error, payload) => {
  if (error) {
    throw error;
  }

  // Get updated accounts and chainId
  // const { accounts, chainId } = payload.params[0];
});

connector.on("disconnect", (error, payload) => {
  if (error) {
    throw error;
  }

  // Delete connector
});

const openConection = () => {
  // Check if connection is already established
  if (!connector.connected) {
    // create new session
    connector.createSession().then(() => {
      // get uri for QR Code modal
      const uri = connector.uri;
      // display QR Code modal
      WalletConnectQRCodeModal.open(uri, () => {
        console.log("QR Code Modal closed");
      });
    });
  }
}

const closeModal = () => {
  // Subscribe to connection events
  connector.on("connect", (error, payload) => {
    if (error) {
      throw error;
    }

    // Close QR Code Modal
    WalletConnectQRCodeModal.close();

    // Get provided accounts and chainId
    // const { accounts, chainId } = payload.params[0];
    // do something
  });
}

const sendTransaction = (transaction) => {
  
  // Send transaction
  connector
    .sendTransaction(transaction)
    .then(result => {
      // Returns transaction id (hash)
      let message = "Transaccion exitosa!";
      console.log(result);
      if (result === null) {
        message = "Transaccion rechazada!"
      }
      return {
        message,
        hash: result,
        error: result === null ? true : false,
      };
    })
    .catch(error => {
      // Error returned when rejected
      console.error(error);
      return {
        message: "Ocurrio un error!",
        error: true,
        errorData: error,
      };
    });
}

export {
  connector,
  openConection,
  closeModal,
  sendTransaction
}
