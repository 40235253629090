
export const handleHttpResponse = (response) => {
  return {        
    code: response.status,
    response: response.data || response.exceptions
  }
}
  
export const handleHttpError = (error) => {
  return {        
    code: error.status,
    response: error.exceptions || error.message || error.data,
  }
}
  
export const buildURLQuery = params => {
  if (!Object.keys(params).length)
    return '';
  let query = "?",
      step  = 1,
      total = Object.keys(params).length;

  Object.entries(params).forEach(([key, value]) => {
    let possibleAmpersand = (step < total ? "&" : "");
    query += key + "=" + value + possibleAmpersand;
    step++;
  });
  return query;
}
  
export const buildDefaultOptions = (accessToken) => {
  return {
    headers: {
      'Authorization': accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };
};