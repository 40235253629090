import React from 'react';

import './styles/index.scss';

const PartialPay = () => (
  <div className="partial-paid">
    <img
      src="https://public-kriptonmarket.s3.amazonaws.com/img/process_payment/warning.svg"
      alt="Saldo deudor"
    />

    <p>Este pago presenta un saldo deudor. Por favor, realice el pago correspondiente.</p>
  </div>
);

export default PartialPay;
