import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createBrowserHistory } from 'history';
import './index.css';
import Reducers from './redux/reducers';
import Routes from './router';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory();

const store = createStore(
  connectRouter(history)(Reducers),
  {},
  process.env.NODE_ENV !== 'production'
    ? applyMiddleware(thunk, logger, routerMiddleware(history))
    : applyMiddleware(thunk, routerMiddleware(history))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <Routes history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
