import React from 'react';
import classnames from 'classnames';

import './styles/index.scss';

const Button = ({ title, callbak, disabled, type, className }) => {
  const buttonClass = classnames('payments-button', className, {
    disabled,
    'primary': type === 'primary',
    'default': type === 'default',
  });

  return (
    <button
      className={buttonClass}
      onClick={callbak}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export default Button;
