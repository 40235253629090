import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigAction } from '../../redux/actionsCreators'
import { useQuery } from '../../hooks';
import { setToken } from '../../redux/actions/payments';
import Header from '../header';
import Footer from '../footer';
import Disclaimer from '../disclaimerDialog';
import Snackbar from '../snackbar';
import Spinner from '../spinner';
import './styles/index.scss';

const Layout = props => {
  const query = useQuery();
  const token = query.get("token");
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Payments.loading);
  const {
    header,
    notification,
  } = useSelector(state => state.Payments.config);

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
      dispatch(getConfigAction());
    }  

  },[dispatch, token]);

  return (
    <section className="app">
      <Snackbar />
      {
        notification?.show && (
          <Disclaimer
            title="AVISO"
            text={notification?.text}
            titleButton="Cerrar"
          />
        )
      }
      {loading && <Spinner />}
      <div className="app-container">
        <section className="app-header">
          <Header {...header} />
        </section>
        <section className="app-content">
          { props.children }
        </section>
        <section className="app-footer">
          <Footer />
        </section>
      </div>
    </section>
  );
}

export default Layout;
