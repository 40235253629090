import React from 'react';
import './styles/index.scss';

const Header = ({
  title = 'Kriptopayments',
  type,
  logo,
}) => (
  <header className="payments-header">
    <div className="payments-header__container">
      <div className="payments-header__icon-custom">
        <img src={logo} alt="kryptopayments-logo"/>
      </div>
      <div className="payments-header__title-custom">
        <h1>{title}</h1>
        <h4>/Orden de pago</h4>
      </div>
    </div>
  </header>
);

export default Header;
