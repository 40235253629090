import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import Card from '../Card';
import Spinner from '../spinner';
import { initializeLoginServices, logoutServices } from '../../redux/actionsCreators';
import { setModalLoginShow } from '../../redux/actions/payments';

import KmLogo from '../../asstes/images/logo-km-simple.svg';
import LogoutIcon from '../../asstes/images/exit-purple.svg';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';

import './styles/index.scss';

const LoginModal = () => {
  const { modalLogin } = useSelector(state => state.Payments);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        initializeLoginServices(
          values[FIELDS_NAME.USERNAME],
          values[FIELDS_NAME.PASSWORD],
          true,
        ),
      );
    },
    [dispatch],
  );

  const {
    handleSubmit,
    getFieldProps,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleLogout = useCallback(
    () => {
      dispatch(logoutServices(true));
    },
    [dispatch],
  )

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(
    () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    },
    [],
  );

  const handleClose = useCallback(
    () => {
      dispatch(setModalLoginShow(false));
    },
    [dispatch],
  );

  return (
    <>
      {modalLogin.show ? (
        <div className="login-modal__container">
          <Card className="login-modal__card-content">
            {Boolean(modalLogin.currentUser.token) && (
              <button
                type="button"
                className="login-modal__logout-button"
                onClick={handleLogout}
              >
                <img
                  src={LogoutIcon}
                  alt="Logout"
                  className="login-modal__logout-button__logout-icon"
                />

                Salir
              </button>
            )}

            <div className="login-modal__logo__container">
              <img
                src={KmLogo}
                alt="kriptoservice-logo"
                className="login-modal__logo__img"
              />
            </div>

            <h1 className="login-modal__title">INICIAR SESIÓN</h1>

            <form onSubmit={handleSubmit} className="login-modal__form">
              <div className="login-modal__form__inputs">
                <div className="login-modal__form__inputs__input-text">
                  <label htmlFor="input-text">
                    Mail/usuario
                  </label>
                
                  <input
                    placeholder="example@gmail.com"
                    type="text"
                    autoComplete="off"
                    {...getFieldProps(FIELDS_NAME.USERNAME)}
                  />
                </div>

                <div className="login-modal__form__inputs__input-password">
                  <label htmlFor="input-password">
                    Contraseña
                  </label>

                  <input
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="off"
                    {...getFieldProps(FIELDS_NAME.PASSWORD)}
                  />
            
                  <span onClick={toggleShowPassword}>
                    {showPassword ? 'OCULTAR' : 'MOSTRAR'}
                  </span>
                </div>
              </div>

              {modalLogin.currentUser.error && (
                <div className="login-modal__form__message-error">
                  <span>{modalLogin.currentUser.error}</span>
                </div>
              )}

              <div className="login-modal__form__buttons">
                <button
                  type="submit"
                  className="login-modal__form__login-button"
                  disabled={!dirty || !isValid || modalLogin.loading}
                >
                  INGRESAR
                </button>

                <button
                  type="button"
                  className="login-modal__form__return-button"
                  onClick={handleClose}
                >
                  VOLVER
                </button>
              </div>
            </form>
          </Card>

          {modalLogin.loading && <Spinner />}
        </div>
      ) : null}

    </>
  );
};

export default LoginModal;