import { useScript } from './useScript';

export const useLoadGoogleAnalitycs = (gaId) => {
  useScript({
    id: 'gtag-initialized',
    position: document.querySelector('head'),
    src: `https://www.googletagmanager.com/gtag/js?${gaId}`,
    async: true,
    validateOnlyProduction: true,
  });

  useScript({
    id: 'gtag-callback',
    position: document.querySelector('head'),
    innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gaId}');
    `,
    validateOnlyProduction: true,
  });
};