import React from 'react';
import { Router, Route, Switch } from "react-router-dom";

import { useLoadGoogleAnalitycs } from '../hooks/useLoadGoogleAnalitycs';
import { useLoadFacebookPixel } from '../hooks/useLoadFacebookPixel';
import Payments from '../pages/payments';
import Pay from '../pages/pay';
import PaymentLink from '../pages/PaymentLink'
import Layout from '../components/layout/';

function Routes({history}) {
  useLoadGoogleAnalitycs('G-KSXL568Z5Q');
  useLoadFacebookPixel('667045987921454');

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/pay" component={Pay} />
        <Layout>
          <Route exact path="/payments" component={Payments} />
          <Route exact path="/payment_link/:payment_link_token" component={PaymentLink} />
          <Route exact path="/payments/cancel" component={Payments} />
          <Route exact path="/payments/success" component={Payments} />
          <Route exact path="/" component={Payments} />
        </Layout>
      </Switch>
    </Router>
  );
};
export default Routes;
