import React from 'react';
import classnames from 'classnames';

import LeftArrow from '../../asstes/images/back.png';
import RightArrow from '../../asstes/images/next.png';

import './styles/index.scss';

const CustomArrows = ({ onClick, left }) => (
  <div
    className={classnames('rewards__carousel_arrow-button', {
      'rewards__carousel_arrow-button__left': left,
    })}
    onClick={onClick}
  >
    {left ? (
      <img src={LeftArrow} alt="Arrow Left" />
    ) : (
      <img src={RightArrow} alt="Right Left" />
    )}
  </div>
);

export default CustomArrows;
