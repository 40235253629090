import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Label from './label';
// import Select from '../select';
import Select, { components } from 'react-select';
import iconSearch from '../../asstes/images/iconSearch.svg';
import './styles/index.scss';

const CustomSelect = ({ handleCrypto }) => {
  const [selectValues, setSelectValues] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const crypto = useSelector(state => state.Payments.crypto);

  const handleSelect = value => {
    setSelectValues(value);
    handleCrypto(value.value);
  }

  const buildSelectObjet = data => {
    const currenciesList = [];
    data.forEach(({ crypto, red }) => {
      currenciesList.push({
        label: <Label crypto={crypto} red={red} />,
        value: `${crypto.name}-${red.name}-${red.id}`,
      })
    });
    return currenciesList;
  }

  useEffect(() => {
    if (crypto.length) {
      const list = buildSelectObjet(crypto);
      setCurrencies(list);

      if (crypto.length === 1) {
        handleSelect({
          label: <Label crypto={crypto[0].crypto} red={crypto[0].red} />,
          value: `${crypto[0].crypto.name}-${crypto[0].red.name}-${crypto[0].red.id}`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crypto]);

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <img src={iconSearch} alt="search icon" />
          {children}
        </components.ValueContainer>
      )
    );
  };

  const styles = {
    valueContainer: base => ({
      ...base,
      paddingLeft: 10
    })
  }

  return (
    <Select
      className="payments-select"
      placeholder=""
      onChange={handleSelect}
      value={selectValues}
      options={currencies}
      components={{ ValueContainer }}
      classNamePrefix="vyrill"
      isSearchable={true}
      styles={styles}
    />
  )
}

export default CustomSelect;
