import * as Yup from 'yup';

export const FIELDS_NAME = {
  CRYPTO_RED: 'cryptoRed',
  CURRENCY: 'currency',
  AMOUNT: 'amount',
};

export const initialValues = {
  [FIELDS_NAME.CRYPTO_RED]: '',
  [FIELDS_NAME.CURRENCY]: '',
  [FIELDS_NAME.AMOUNT]: null,
};

export const validationSchema = () =>
  Yup.object().shape({
    [FIELDS_NAME.CRYPTO_RED]: Yup.string().required(),
    [FIELDS_NAME.CURRENCY]: Yup.string().required(),
    [FIELDS_NAME.AMOUNT]: Yup.number().nullable().required()
  });

