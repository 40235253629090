import React from 'react';
import classNames from 'classnames';

import './styles/index.scss';

const CustomDot = ({ index, onClick: goToSlide, active }) => (
  <li data-index={index} key={index}>
    <button
      aria-label={`Go to slide ${index ?? 0 + 1}`}
      onClick={goToSlide}
      className={classNames('rewards__carousel_dot-item-button', {
        'rewards__carousel_dot-item-button__active': active,
      })}
    />
  </li>
);

export default CustomDot;
