import React, { useState } from 'react';
import './styles/index.scss';

import Button from '../button';

const SharedModal = ({ title, text, titleButton }) => {
  const [showModal, setShow] = useState(true);

  const handleClick = () => {
    setShow(!showModal);
  };

  return showModal ? (
    <div className="modal__container">
      <div className="modal__card">
        <div className="modal__content">
          <span className="modal__close" onClick={handleClick}>X</span>
          <h3>{title}</h3>
          <p>{text}</p>
          <Button title={titleButton} callbak={handleClick} />
        </div>
      </div>
    </div>
  ) : null;
}

export default SharedModal;
