let initialState = {
  currencies: [],
  loading: false,
  token: '',
  config: {},
  crypto: [],
  invoice: {},
  invoiceStatus: null,
  snackbar: {
    open: false,
    messagge: '',
    severity: ''
  },
  modalLogin: {
    show: false,
    loading: false,
    currentUser: {
      token: null,
      error: null,
    }
  },
  rewards: [],
  loadingCreateReward: false,
  paymentLink: {
    info: {},
    errorCreate: false,
  },
}

export default function Payments(state = initialState, action) {
  switch (action.type) {
    case 'GET_CURRENCIES':
      return { ...state, currencies: action.payload }

    case 'SET_LOADING':
      return { ...state, loading: !state.loading }

    case 'SET_TOKEN':
      return { ...state, token: action.payload }

    case 'SET_SNACKBAR':
      return { ...state, snackbar: action.payload }

    case 'SET_CONFIG':
      return { ...state, config: action.payload }
    
    case 'SET_CRYPTO':
      return { ...state, crypto: action.payload }

    case 'SET_INVOICE':
      return { ...state, invoice: action.payload }
    
    case 'SET_INVOICE_STATUS':
      return { ...state, invoiceStatus: action.payload }

    case 'SET_MODAL_LOGIN_SHOW':
      return { ...state, modalLogin: { ...state.modalLogin, show: action.payload } }

    case 'SET_MODAL_LOGIN_LOADING':
      return { ...state,  modalLogin: { ...state.modalLogin, loading: action.payload } }
    
    case 'SET_MODAL_LOGIN_CURRENT_USER':
      return { ...state,  modalLogin: { ...state.modalLogin, currentUser: action.payload } }

    case 'SET_REWARDS':
      return { ...state, rewards: action.payload }

    case 'SET_LOADING_CREATE_REWARD':
      return { ...state, loadingCreateReward: !state.loadingCreateReward }

    case 'SET_PAYMENT_LINK_INFO':
      return { ...state, paymentLink: { ...state.paymentLink, info: action.payload } }

    case 'SET_PAYMENT_LINK_ERROR_CREATE':
      return { ...state, paymentLink: { ...state.paymentLink, errorCreate: action.payload } }

    default:
      return {
        ...state
      }
  }
}
