// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1xIte {\n  background-color: #FFFFFF;\n  border-radius: 20px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 20px;\n  padding: 20px; }\n", "",{"version":3,"sources":["webpack://src/components/Card/styles.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBCIa;EDHb,mBAAmB;EACnB,0CAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,aAAa,EAAA","sourcesContent":["@import \"../../styles/variables.scss\";\n\n.container {\n  background-color: $white;\n  border-radius: 20px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 20px;\n  padding: 20px;\n}\n","// Colors\n$orange: #F89737;\n$orange-darker: #EF6F11;\n$orange-light: #F2AB53;\n$orange-middle: #F39224;\n$orange-header: #F4882A;\n$black: #161615;\n$white: #FFFFFF;\n$gray-dark: #707070;\n$gray-light: #00000029;\n$gray-lighter: #e0e0e0;\n$purple: #8B54F7;\n$red: #f44336;\n$green: #4caf50;\n\n// Spacing\n$spacing-8: 8px;\n$spacing-10: 10px;\n$spacing-12: 12px;\n$spacing-14: 14px;\n$spacing-16: 16px;\n$spacing-20: 20px;\n$spacing-24: 24px;\n$spacing-28: 28px;\n$spacing-32: 32px;\n$spacing-36: 36px;\n$spacing-40: 40px;\n$spacing-44: 44px;\n$spacing-48: 48px;\n$spacing-52: 52px;\n$spacing-56: 56px;\n$spacing-60: 60px;\n\n// Font-size\n$font-size-8: 8px;\n$font-size-10: 10px;\n$font-size-12: 12px;\n$font-size-14: 14px;\n$font-size-16: 16px;\n$font-size-18: 18px;\n$font-size-20: 20px;\n$font-size-24: 24px;\n$font-size-28: 28px;\n$font-size-32: 32px;\n$font-size-40: 40px;\n$font-size-48: 48px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1xIte"
};
export default ___CSS_LOADER_EXPORT___;
