import React from 'react';
import './styles/index.scss';

const Label = ({ crypto, red }) => (
  <div className="select-label align-center">
    <div className="select-label__crypto">
      <img src={crypto.icon} alt={crypto.name} />
      <span className="select-label__title">{crypto.name}</span>
    </div>
    <div className="select-label__red">
      <img src={red.icon} alt={red.name} />
      <span className="select-label__title">{red.name}</span>
    </div>
  </div>
);

export default Label;
