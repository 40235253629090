import React from 'react';
import kmLogo from '../../asstes/images/kmlogo.svg';
import './styles/index.scss';

const Footer = () => (
	<footer className="payments-footer">
		<div className="payments-footer__left-content">
			<div className="payments-footer__icon">
				<img src={kmLogo} alt="kriptopayments-logo"/>
			</div>
			<div className="payments-footer__disclaimer">
				<p>Copyright 2020 Kripton Market SA | Todos los derechos reservados</p>
			</div>
		</div>
		<div className="payments-footer__right-content">
			<span>Terminos y Condiciones</span>
			<span>Politicas de privacidad</span>
			<span>Ayuda</span>
		</div>
	</footer>
)

export default Footer;
