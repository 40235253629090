import * as Yup from 'yup';

export const FIELDS_NAME = {
  USERNAME: 'username',
  PASSWORD: 'password',
};

export const initialValues = {
  [FIELDS_NAME.USERNAME]: '',
  [FIELDS_NAME.PASSWORD]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.USERNAME]: Yup.string().email().required(),
  [FIELDS_NAME.PASSWORD]: Yup.string().required(),
});