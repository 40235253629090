import { httpGet, httpPost } from './httpService';
import {
  buildDefaultOptions, 
  buildURLQuery,
  handleHttpResponse, 
  handleHttpError
} from '../../utils/http';

export const get = async (entity_uri, getValues, token = '') => {    
  const headerOptions = buildDefaultOptions(token);
  const newParams = {
    ...getValues,
  }
  const queryString = buildURLQuery(newParams);
  const uri = `${entity_uri}${queryString}`   
	
  try {
    const response = await httpGet(uri, headerOptions);
    return handleHttpResponse(response);
  }
  catch (error) {
    return handleHttpError(error.response);
  }
}

export const post = async (entity_uri, body, token) => {
  const headerOptions = buildDefaultOptions(token);
  try {
    const response = await httpPost(entity_uri, body, headerOptions);
    return handleHttpResponse(response);
  }
  catch(error) {
    return handleHttpError(error.response);
  }
}
			