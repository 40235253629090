import { useRef, useMemo, useEffect } from 'react';

const loadScript = ({
  id,
  position,
  src,
  innerHTML,
  async = false,
  type,
  charset,
  crossOrigin,
}) => {
  if (!position) return;

  const script = document.createElement('script');

  script.setAttribute('id', id);

  if (src) script.src = src;

  if (innerHTML) script.innerHTML = innerHTML;

  if (async) script.setAttribute('async', '');

  if (type) script.setAttribute('type', type);

  if (charset) script.setAttribute('charset', charset);

  if (crossOrigin) script.setAttribute('crossorigin', crossOrigin);

  position.appendChild(script);
};

export const useScript = ({
  id,
  position,
  src,
  innerHTML,
  async = false,
  type,
  charset,
  crossOrigin,
  validateOnlyProduction = false,
}) => {
  const loaded = useRef(false);

  const isValid = useMemo(
    () => (validateOnlyProduction ? process.env.NODE_ENV === 'production' : true),
    [validateOnlyProduction],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current && isValid) {
      if (!document.querySelector(`#${id}`)) {
        loadScript({
          id,
          position,
          src,
          innerHTML,
          async,
          type,
          charset,
          crossOrigin,
        });
      }

      loaded.current = true;
    }

    return () => {
      loaded.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loaded: loaded.current };
};
