import React from 'react';
import './styles/index.scss';

import Constants from '../../utils/constants';
import Error from '../../asstes/images/error.svg';
import Success from '../../asstes/images/success.svg';

const { MODAL: { ERROR, SUCCESS } } = Constants;

const Modal = ({ status, show }) => {
  if (!show) return null;

  const titleToShow = status ? SUCCESS.TITLE : ERROR.TITLE;
  const messageToShow = status ? SUCCESS.SUBTITLE : ERROR.SUBTITLE;
  const iconToShow = status ? Success : Error;

  const handleClick = () => window.location.href = 'https://kriptonmarket.com/';

  return (
    <div className="modal__container">
      <div className="modal__card">
        <div className="modal__content">
          <span className="modal__close" onClick={handleClick}>X</span>
          <h3>{titleToShow}</h3>
          <div>
            <img src={iconToShow} alt={titleToShow} />
          </div>
          <p>{messageToShow}</p>
        </div>
      </div>
    </div>
  );
}

export default Modal;
