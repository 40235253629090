import React from 'react';
import { useDispatch } from 'react-redux';

import { setSnackbar } from '../../redux/actions/payments';
import Constants from '../../utils/constants';

import './styles/index.scss';

const { TYPES: { SUCCESS } } = Constants;

const CopyButton = ({ text }) => {
  const dispatch = useDispatch();

  const copyToClip = () => {
    if (typeof navigator === 'object') {
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        dispatch(setSnackbar({
          open: true,
          message: 'Texto copiado exitosamente!',
          severity: SUCCESS
        }))
      }, 500)
    }
  }
  return (
    <button type="button" className='button-copy' onClick={copyToClip}>
      <img
        src="https://public-kriptonmarket.s3.amazonaws.com/img/process_payment/copy_info.svg"
        alt="Copy"
      />
    </button>
  )
};

export default CopyButton;
