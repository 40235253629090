import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { validateInputValue } from '../../utils/helpers';
import './styles/index.scss';

const Input = ({ getValue, placeholder, type, defaultValue, disabled = false }) => {
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		if (defaultValue !== undefined) {
			setInputValue(defaultValue);
		}
	}, [defaultValue])

	const handleOnChange = event => {
		const { value } = event.target;
		if (type && value && (isNaN(value) || !validateInputValue(type, parseInt(value)))) {
			return false;
		}
		setInputValue(value);
		getValue(value);
	};

	const inputClass = classNames('payments-input', {
		error: (inputValue && !validateInputValue(type, inputValue))
	})

  return (
		<input
			value={inputValue}
			onChange={handleOnChange}
			className={inputClass}
			placeholder={placeholder}
			disabled={disabled}
		/>
	);
}

export default Input;
