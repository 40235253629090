export const GET_CURRENCIES = 'GET_CURRENCIES';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_LOADING = 'SET_LOADING';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CRYPTO = 'SET_CRYPTO';
export const SET_INVOICE = 'SET_INVOICE';
export const SET_INVOICE_STATUS = 'SET_INVOICE_STATUS';
export const SET_MODAL_LOGIN_SHOW = 'SET_MODAL_LOGIN_SHOW';
export const SET_MODAL_LOGIN_LOADING = 'SET_MODAL_LOGIN_LOADING';
export const SET_MODAL_LOGIN_CURRENT_USER = 'SET_MODAL_LOGIN_CURRENT_USER';
export const SET_REWARDS = 'SET_REWARDS';
export const SET_LOADING_CREATE_REWARD = 'SET_LOADING_CREATE_REWARD';
export const SET_PAYMENT_LINK_INFO = 'SET_PAYMENT_LINK_INFO';
export const SET_PAYMENT_LINK_ERROR_CREATE = 'SET_PAYMENT_LINK_ERROR_CREATE';

export const setCurrencies = (payload) => ({
  type: GET_CURRENCIES,
  payload
});

export const setCrypto = (payload) => ({
  type: SET_CRYPTO,
  payload
});


export const setSnackbar = (payload) => ({
  type: SET_SNACKBAR,
  payload
});

export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload
});

export const setLoading = () => ({
  type: SET_LOADING
});

export const setConfig = (payload) => ({
  type: SET_CONFIG,
  payload
});

export const setInvoice = (payload) => ({
  type: SET_INVOICE,
  payload
});

export const setInvoiceStatus = (payload) => ({
  type: SET_INVOICE_STATUS,
  payload
});

export const setModalLoginShow = (payload) => ({
  type: SET_MODAL_LOGIN_SHOW,
  payload
});

export const setModalLoginLoading = (payload) => ({
  type: SET_MODAL_LOGIN_LOADING,
  payload
});

export const setModalLoginCurrentUser = (payload) => ({
  type: SET_MODAL_LOGIN_CURRENT_USER,
  payload
});

export const setRewards = (payload) => ({
  type: SET_REWARDS,
  payload,
});

export const setLoadingCreateReward = () => ({
  type: SET_LOADING_CREATE_REWARD
});

export const setPaymentLinkInfo = (payload) => ({
  type: SET_PAYMENT_LINK_INFO,
  payload,
});

export const setPaymentLinkErrorCreate = (payload) => ({
  type: SET_PAYMENT_LINK_ERROR_CREATE,
  payload,
});
