import React from "react";

import './styles/index.scss';

const CheckingPayment = () => {
  return (
    <div className="checking-container">
      <img
        src="https://public-kriptonmarket.s3.amazonaws.com/img/process_payment/spinner.svg"
        alt="Spinner"
        className="rotating"
        />

      <p>Aguardando confirmación del pago.</p>
    </div>
  )
};

export default CheckingPayment;
