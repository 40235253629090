import React, { useMemo, useState, useCallback  } from 'react';
import Carousel from 'react-multi-carousel';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import classnames from 'classnames';

import Input from '../input';
import Button from '../button';
import CustomArrows from './CustomArrows';
import CustomDot from './CustomDot';
import Constants from '../../utils/constants';
import { formatCurrencyValue } from '../../utils/helpers';
import { savePaymentReward } from '../../redux/actionsCreators';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';

import './styles/index.scss';
import "react-multi-carousel/lib/styles.css";

const responsiveCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 599, min: 326 },
    items: 2,
    slidesToSlide: 2,
  },
  extraMobile: {
    breakpoint: { max: 325, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Rewards = ({ invoiceToken }) => {
  const { rewards, modalLogin } = useSelector(state => state.Payments);
  const dispatch = useDispatch()

  const hasRewards = useMemo(() => rewards.length > 0, [rewards]);

  const [selectedCurrency, setSelectedCurrency] = useState('');

  const getTokenBalance = useCallback(
    (amount) => formatCurrencyValue(Number(amount), { style: 'decimal' }),
    [],
  );

  const onSubmit = useCallback(
    (values, actions) => {
      
      dispatch(
        savePaymentReward(
          values[FIELDS_NAME.AMOUNT],
          selectedCurrency,
          invoiceToken,
          modalLogin.currentUser.token,
          () => {
            actions.resetForm();
            setSelectedCurrency('');
          }
        ),
      )
    },
    [dispatch, invoiceToken, modalLogin.currentUser.token, selectedCurrency],
  );

  const {
    handleSubmit: submitForm,
    setFieldValue,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  const handleAmountChange = useCallback(
    (value) => {
      const currentVal = parseFloat(value);

      setFieldValue(FIELDS_NAME.AMOUNT, currentVal);
    },
    [setFieldValue],
  );

  return (
    <div className="rewards__container">
      <h2 className="rewards__title">
        Mis Rewards
      </h2>

      {!hasRewards && (
        <span>Este cliente no tiene saldo en ningun token</span>
      )}

      {hasRewards && (
        <>
          <Carousel
            showDots
            responsive={responsiveCarousel}
            containerClass="rewards__carousel__container"
            sliderClass="rewards__carousel__list-container"
            itemClass="rewards__carousel__item-container"
            shouldResetAutoplay={false}
            autoPlay={false}
            customRightArrow={<CustomArrows />}
            customLeftArrow={<CustomArrows left />}
            customDot={<CustomDot />}

          >
            {rewards.map((reward) =>  (
              <button
                type="button"
                className={classnames('rewards__item-card', {
                  'rewards__item-card__selected': selectedCurrency === reward.currency,
                })}
                key={`reward-${reward.currency}`}
                onClick={() => setSelectedCurrency(reward.currency)}
              >
                <div className="rewards__item-card__title">
                  <div className="rewards__item-card__img">
                    {reward.logo && (
                      <img
                        src={reward.logo}
                        alt={reward.currency}
                      />
                    )}
                  </div>
                
                  <span className="rewards__item-card__cripto-name">
                    {reward.currency.toUpperCase()}
                  </span>
                </div>

                <span className="rewards__item-card__cripto-balance">
                  <span className="rewards__item-card__cripto__cripto-balance__small">Saldo</span>

                  {getTokenBalance(reward.amount)}
                </span>

                <span className="rewards__item-card__cripto-balance">
                  <span className="rewards__item-card__cripto__cripto-balance__small">Saldo USD</span>

                  {getTokenBalance(reward.amount_usd)}
                </span>
              </button>
            ))}
          </Carousel>

          <div className="rewards-form__container">
            <div className="rewards-form__input-text">
              <label className="payments-labels">Monto a pagar</label>

              <Input
                placeholder=""
                type={Constants.INPUTS_TYPES.AMOUNT}
                getValue={handleAmountChange}
              />
            </div>

            <Button
              title="PAGAR"
              type="primary"
              disabled={!dirty || !isValid || !selectedCurrency} 
              callbak={submitForm} 
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Rewards;
