import React from "react";

import CopyButton from "../copyButton";
import "./styles/index.scss";

const CardPaymentHeader = ({ amount = "", symbol = "", icon = "" }) => (
  <div className="card-payment-amount-header">
    <img
      src="https://public-kriptonmarket.s3.amazonaws.com/img/process_payment/logo_header.svg"
      alt="KM Logo"
    />

    <span className="amount-header">
      <img
        className="currency-icon"
        src={`${icon}`}
        alt={`${symbol} Icon`}
      />
      {`${symbol} ${amount}`}
    </span>

    <CopyButton text={amount} />
  </div>
);

export default CardPaymentHeader;
