import React from 'react';
import './styles/index.scss';

import Error from '../../asstes/images/error.svg';

const ModalErrorPaymentLink = ({ show }) => {
  if (!show) return null;

  const handleClick = () => window.location.href = 'https://kriptonmarket.com/';

  return (
    <div className="modal__container">
      <div className="modal__card">
        <div className="modal__content">
          <span className="modal__close" onClick={handleClick}>X</span>
          <h3>Link de pago vencido</h3>
          <div>
            <img src={Error} alt="Error Icon" />
          </div>
          <p>El link de pago ya fue completado o expiro su tiempo de vida. Para realizar el pago comuniquese con su proveedor.</p>
        </div>
      </div>
    </div>
  );
}

export default ModalErrorPaymentLink;
