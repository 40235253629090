import BigNumber from "bignumber.js";
import Constants from './constants';

const { INPUTS_TYPES: { EMAIL, AMOUNT }, PATHS } = Constants;

const validateEmail = (email) => {
  const reg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return reg.test(email);
}

function validateAmount(value) {
  const reg = /^\$?[\d,]+(\.\d*)?$/;

  return !isNaN(value) && reg.test(value);
}

export const validateInputValue = (type, value) => {
  switch(type) {
    case EMAIL:
      return validateEmail(value);
    case AMOUNT:
      return validateAmount(value);
    default:
      return true;
  }
}

export const transformCurrencies = data =>
  data
    .filter(({ allow }) => allow)
    .map(({ id, name}) => ({ value: id, label: name}))

export const buildCurrenciesList = data => 
  data
    .filter(({ allow }) => allow)
    .map(({ id, name, icon, currency_icon, currency_name }) => ({
      crypto: { name: currency_name, icon: currency_icon },
      red: { name, id, icon },
    }));

export const isValidForm = (data, validationSchema) => {
  const keys = Object.keys(data);
  let valid = true;

  if (keys.length) {
    for(const key of keys) {
      if (validationSchema[key] === 'required') {
        if (!data[key]) {
          valid = false;
        }
      }
    }

    return valid;
  }
}

export const serializeToken = query => {
  if (query) {
    return query.split('=')[1] || '';
  }
}

export const showModal = path => {
  const showModal = {
    show: false,
    status: false
  }
  if (path === PATHS.SUCCESS) {
    showModal['show'] = true;
    showModal['status'] = true;
  }
   if (path === PATHS.CANCEL) {
    showModal['show'] = true;
    showModal['status'] = false;
   }

  return showModal;
}

export const convertStringToHex = (value) => {
  return new BigNumber(`${value}`).toString(16);
}

export const sanitizeHex = (hex) => {
  hex = hex.substring(0, 2) === "0x" ? hex.substring(2) : hex;
  if (hex === "") {
    return "";
  }
  hex = hex.length % 2 !== 0 ? "0" + hex : hex;
  return "0x" + hex;
}

export const calculateERC20Amount = (amount, decimalPlaces) => {
  // Calculate the amount of the ERC20 token in its base units
  return new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimalPlaces)).toFixed();
}

export const formatCurrencyValue = (
  value,
  options = { style: 'currency', currency: 'ARS' },
) =>
  new Intl.NumberFormat('es-AR', {
    ...options,
    maximumFractionDigits: 8,
  }).format(value);
