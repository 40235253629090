import * as Yup from 'yup';

export const FIELDS_NAME = {
  CRYPTO_RED: 'cryptoRed',
};

export const initialValues = {
  [FIELDS_NAME.CRYPTO_RED]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CRYPTO_RED]: Yup.string().required(),
});