import React from 'react';
import QRCode from 'react-qr-code';

import CopyButton from '../copyButton';

import './styles/index.scss';

const QRCodeGenerator = ({ address = "", qr }) => (
  <div className="card-content">
    {qr && <QRCode value={qr} size={200} />}

    <div className='card-content__address'>
      <p className="card-content__text">{address?.substring(0, 27)}...</p>
      <CopyButton text={address} />
    </div>
  </div>
);

export default QRCodeGenerator;
