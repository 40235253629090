import React from 'react';
import ImageSpinner from '../../asstes/images/spinner.svg';
import './styles/index.scss';

const Spinner = () => (
  <div className="spinner">
    <img src={ImageSpinner} alt="kriptoPayments-wait" />
  </div>
);

export default Spinner;
