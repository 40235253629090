import * as Yup from 'yup';

export const FIELDS_NAME = {
  AMOUNT: 'amount',
};

export const initialValues = {
  [FIELDS_NAME.AMOUNT]: null,
};

export const validationSchema =  Yup.object().shape({
  [FIELDS_NAME.AMOUNT]: Yup.number().nullable().required(),
});