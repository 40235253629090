import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../redux/actions/payments';
import Constants from '../../utils/constants';
import './styles/index.scss';

const { TYPES: { ERROR, SUCCESS}, SNACKBAR_TIMEOUT } = Constants;

const defaultValue = {
  open: false,
  message: '',
  severity: ''
}
const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { open, severity, message } = useSelector(state => state.Payments.snackbar);
  if (!open) {
    return null;
  }

  const handleClose = () => dispatch(setSnackbar(defaultValue));
  const contentClass = classNames('snackbar-content', {
    error: severity === ERROR,
    success: severity === SUCCESS,
  });

  setTimeout(() => {
    handleClose();
  }, SNACKBAR_TIMEOUT);

  return (
    <div className="snackbar-container" onClick={handleClose}>
      <div className={contentClass}>
        <span className="snackbar-close" onClick={handleClose}>x</span>
        <p>{message}</p>
      </div>
    </div>
  );
}
export default SnackbarComponent;
